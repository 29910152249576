import React, {useEffect, useState} from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from "./router";
import {HashRouter} from "react-router-dom";
import {CheckToken} from "./api";
import {useDispatch} from "react-redux";
import {ToastContainer} from "react-toastify";
import Logo from './assets/img/SVG/Logo.svg'
import * as Sentry from '@sentry/react';

const App = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(Boolean)
    useEffect(() => {
        setLoading(true)
        CheckToken().then((response) => {
            if (response.status === 200) {
                dispatch({type: 'login', startApp: true, login: response.data})
                setLoading(false)
            } else {
                dispatch({type: 'login', startApp: false})
                setLoading(false)

            }
        }).catch((e) => {
            dispatch({type: 'login', startApp: false})
            // setLoading(false)
        })
    }, [])
    return (
        <>
            {
                !loading ?
                    <HashRouter>
                        <ToastContainer rtl={true}/>
                        <Router/>
                    </HashRouter>
                    :
                    <div className="loading">
                        <img src={Logo} alt=""/>
                    </div>
            }
        </>
    )
}

// export default App;
export default Sentry.withProfiler(App);
