import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Negative from "../assets/img/SVG/negative.svg";
import Positive from "../assets/img/SVG/positive.svg";
import Drive from "../assets/img/1x/Drive-Small.png";
import Ups from "../assets/img/1x/UPS.png";
import {useDispatch, useSelector} from "react-redux";
import {AddItemOrders, GETOrders, GetProduct, Separator} from "../api";
import MultiTabs from "./multiTabs";
import Spinner from "../assets/img/SVG/Spinner.svg";
import {toast} from "react-toastify";

const Product = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const data = useSelector(state => state)
    const [total, setTotal] = useState({ControlPanel: 0, UninterruptiblePowerSupply: 0})
    const [products, setProducts] = useState([])
    const [show, setShow] = useState('')

    const error = (e) => toast.error(e);

    const negative = (v) => {
        switch (v.type) {
            case 'ControlPanel':
                setTotal({
                    ...total,
                    ControlPanel: total.ControlPanel > 0 ? total.ControlPanel - 1 : total.ControlPanel
                });
                break;
            case 'UninterruptiblePowerSupply':
                setTotal({
                    ...total,
                    UninterruptiblePowerSupply: total.UninterruptiblePowerSupply > 0 ? total.UninterruptiblePowerSupply - 1 : total.UninterruptiblePowerSupply
                })
                break;
            default:
                return '';
        }
    }

    const plus = (v) => {
        switch (v.type) {
            case 'ControlPanel':
                setTotal({
                    ...total,
                    ControlPanel: total.ControlPanel > -1 ? total.ControlPanel + 1 : total.ControlPanel
                });
                break;
            case 'UninterruptiblePowerSupply':
                setTotal({
                    ...total,
                    UninterruptiblePowerSupply: total.UninterruptiblePowerSupply > -1 ? total.UninterruptiblePowerSupply + 1 : total.UninterruptiblePowerSupply
                })
                break;
            default:
                return '';
        }

        // setTotalDrive(totalDrive > -1 ? totalDrive + 1 : totalDrive)
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        GetProduct().then((response) => {
            setProducts(response.data)
        })
    }, [])

    useEffect(() => {
        dispatch({
            type: 'url',
            url: false
        })
        return () => {
            dispatch({
                type: 'url',
                url: true
            })
        }
    }, [])

    const Counter = async () => {
        if (total.ControlPanel > 0 || total.UninterruptiblePowerSupply > 0) {
            let body = []
            if (total.ControlPanel > 0 && total.UninterruptiblePowerSupply > 0) {
                body = [
                    {
                        productId: products[0].id,
                        units: total.ControlPanel
                    },
                    {
                        productId: products[1].id,
                        units: total.UninterruptiblePowerSupply
                    }
                ]
            } else if (total.ControlPanel > 0) {
                body = [
                    {
                        productId: products[0].id,
                        units: total.ControlPanel
                    }
                ]
            } else if (total.UninterruptiblePowerSupply > 0) {
                body = [
                    {
                        productId: products[1].id,
                        units: total.UninterruptiblePowerSupply
                    }
                ]
            }
            // if (data.login.nationalIdConfirmed) {
                AddItemOrders(body).then((response) => {
                    setTotal({
                        UninterruptiblePowerSupply: 0,
                        ControlPanel: 0
                    });
                    GETOrders('?filters=status==drafted').then((response) => {
                        if (response.status === 200) {
                            dispatch({
                                type: 'product',
                                product: !!response.data ? response.data : []
                            })
                            history.push('/dashboard/allOrders')
                        }

                    })
                })
            // }else {
            //     error('لطفا اطلاعات خود را تکمیل کنید')
            //     history.push('/dashboard/profile')
            // }

        }
    }

    return (
        <div className='product'>
            <div className="line-space"/>
            <div className="line-space"/>

            <div className="product-list">
                {!!products && products.length > 0 ?
                    <>
                        {
                            products.map((v, i) => {
                                return <div key={i} className="product-right">
                                    <div className="text-center">
                                        {
                                            i === 0 &&
                                            <>
                                                <div className="line-space"/>
                                                <img width={290} height={250} src={Drive} alt=""
                                                     className={'product-Drive'}/>
                                                <div className="line-space"/>
                                            </>
                                        }
                                        {
                                            i === 1 &&
                                            <>
                                                <img width={220} height={250} src={Ups} alt=""
                                                     className={'product-ups'}/>


                                            </>
                                        }

                                        <div className="line-space"/>
                                        <div className="line-space"/>
                                        <div className="line-space"/>
                                        <p>{v.title}</p>
                                    </div>
                                    <span style={{textAlign: "left"}}>

                                    {
                                        v.discount > 0 &&
                                        <span>
                                        <span className={'price-line'}>{Separator(v.price)}</span>
                                        <span className={''}> تومان</span>
                                        </span>

                                    }
                                    <div className="line-space"/>
                                    <p> {Separator(v.price * (1 - (v.discount / 100)))} تومان </p>

                                          </span>
                                    <div className="body">
                                        <p onClick={() => {
                                            setShow(v.type)
                                            // window.scrollTo(0, window.innerHeight - 400)
                                            // let myDiv = document.getElementsByClassName('bottom');
                                            // myDiv.scrollTop = 0;
                                            window.scrollTo(0, window.outerHeight)


                                        }}
                                           className={'completely p-2 cursor-p ' + (show === v.type ? 'completely-active' : '')}>اطلاعات
                                            تکمیلی</p>
                                    </div>
                                    <div className="counter-product">
                                <span>
                                    <img width={25} height={25} src={Positive} alt=""
                                         onClick={() => plus(v)}/>
                                </span>
                                        <span>
                                   {total[v.type]}
                                </span>
                                        <span>
                                    <img width={25} height={25} src={Negative} alt=""
                                         onClick={() => negative(v)}/>
                                </span>
                                    </div>
                                </div>
                            })
                        }


                    </>
                    :
                    <div className="d-flex justify-content-center align-self-center align-items-center h-100">
                        <img width={45} src={Spinner} alt=""/>
                    </div>
                }

            </div>
            {!!products && products.length > 0 &&
            <>
                <h6 className={'text-center pt-3 pb-3 p-3'}>
                    خدمات پس از فروش محصولات خریداری شده از پلتفرم چینه تنها در استان خراسان قابل ارائه می باشد.
                </h6>
                <div className="d-flex justify-content-center mt-4">
                    <div className="bottom bottom-w" onClick={() => Counter()}><span>افزودن سفارش جدید</span></div>
                </div>
            </>


            }
            {
                show === 'ControlPanel' &&
                <MultiTabs title={['ویژگی ها', 'مدارک فنی', 'مشخصات فنی', 'مشخصات درایو']}
                           content={'ControlPanel'}/>
            }
            {
                show === 'UninterruptiblePowerSupply' &&
                <MultiTabs title={['ویژگی ها']}
                           content={'UninterruptiblePowerSupply'}/>
            }

            <div className="line-space"/>
            <div className="line-space"/>
        </div>
    );
};

export default Product;
