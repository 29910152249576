import React, {useState} from 'react';
import Finger from '../assets/img/1x/finger.png'
import {FixNumbers, RegisterApi} from "../api";
import {useHistory} from "react-router-dom";
import ErrorAlert from "./errorAlert";
import Delete from "../assets/img/1x/delete.png";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import {getAnalytics, logEvent} from "firebase/analytics";
import {toast} from "react-toastify";

let formData = new FormData();
const Register = () => {
    const analytics = getAnalytics();
    const [information, setInformation] = useState({birthday: ''})
    const [error, setError] = useState('');
    const [rol, setRol] = useState(false)
    const [load, setLoad] = useState(false)
    const [checkBox, setCheckBox] = useState(false);
    const history = useHistory()
    // ░░░░

    // console.log('                                                                                            \n' +
    //     '                                      .%&&&&@@@@&@&&@@@&@@@/,                                       \n' +
    //     '                                 ,&&&@&@@@@@@@@@@@@@@@@&&&@@&@&&/                                   \n' +
    //     '                              (&&&&&&&&@@&&@@&&@@&&&&&&&&&&&&&&@&&&&                                \n' +
    //     '                            (&&&&&&&&&&@@&&&&&&&&&&&@@@&&&%&&&&&%&%&%,                              \n' +
    //     '                           &&&&&&&&&&&&&&&&&@&&&&&&&&&&&&&&@&&&&&&@&&@                              \n' +
    //     '                          &&&&&&&&@@@@&&&&&&&&&&&&&&&&&&&&&&@&&&&&&&@,                              \n' +
    //     '                         #&@&&&%&&&&&%%###%%%%%#######%%%%%#%#####%&&&/                             \n' +
    //     '                         &&@&&&&&&%#(*,,,.,,..................,,,,*(%&&%                            \n' +
    //     '                        (&&&&&&&%(*.....     ...................,,,*(%&&&                           \n' +
    //     '                        /&&&&&%%/,.....    ......................,,*(#&&&%                          \n' +
    //     '                        %&&%%%(*,,........    .......     .......,,,/#%&&&*                         \n' +
    //     '                        /&&%%(/*,,,,,,...                    ..,,,,,**(%&&&                         \n' +
    //     '                        ,&%#(/**//###((((*,.          , /%%%%%%%%&%/***#%&@                         \n' +
    //     '                         %%#///#%%%%%%%&&&&%#(#*    .((##%%#((#/*/(#(**(%&%                         \n' +
    //     '                         %%#/*,*,,***/(/**//*//*    ,**/((#&&&##((*****/&&*                         \n' +
    //     '                         ,%#*,,.,(%( %%&#  /*//*.  .,,./** *&% .*#(,,,*/&&.*                        \n' +
    //     '                        //%(*,...,*////****,,,,,.  ..,,...,,,**,,....,**#(,,.                       \n' +
    //     '                        **/(*,..   ..........,,,,. ......          ...,*((,,                        \n' +
    //     '                         *,%/*,..        ...,,*,.....,...          ..,,*(#,*                        \n' +
    //     '                         ,*&/*,..        ..,*/*.    ..,.           ...*/%(..                        \n' +
    //     '                         *(%%/,,..       ..*/*/*,  .,/*,.         ...,*(#**.                        \n' +
    //     '                           /((*,,...      ..,*/(,,.,,/,...      .....,*/(,                          \n' +
    //     '                           */(/*,,,..     .,,***,*,*.,**,,,,    .....*//*,                          \n' +
    //     '                             .(**,*,..../***,*.,**,,/*,.,,,**,...,,..,*(                            \n' +
    //     '                              /(*,,**,.,***/(#((////**/((/**,..,,*,.,,/,                            \n' +
    //     '                               (/**,***,,*....,,,,,,*,,.....,,,,,**,,*/                             \n' +
    //     '                                ((*///*,,,,....,,*/(*,,...,.**,**/**/*                              \n' +
    //     '                                 ,((/(//****,......,.. ...,**///((((                                \n' +
    //     '                                 #/((((((((//*,.,.,,..,..*/((###(#,                                 \n' +
    //     '                                %%*/((#((###(((/////(/(((######(((.                                 \n' +
    //     '                               .%&*///((((((##############((((((//##                                \n' +
    //     '                              ,. &****////////////******///////*//%%#                               \n' +
    //     '                              .,  #*****//*******,,....,***//**,*/#&#                               \n' +
    //     '                        .,(.   ., .%/*,,**********,,.,*******,,,,*&@*...                            \n' +
    //     '                    .,.   ..    .*  .#/*,,,,**,,*,,,*****,*,,..,*#&*. ...  ..                       \n' +
    //     '               ..  .       ..      ,.   //**,,,,,...,....,,...,/%#.*,      ...   ..                 \n' +
    //     '          ..   ..                   . .,.  ,//**,,..........,*(%#.*.  .         ....  ...           \n' +
    //     '     ..   .                            ...    .***,,......,*(#%( .           .,. .   ..,.  .,,.     \n' +
    //     '.                                          ...,  ........,/#%%  &/           .,.  .,   .    ..  ....\n' +
    //     '        .                                    ,*    .,,*/((*  *#(.             ..  ...  .            \n' +
    //     '                                      ..    ,/   .  (#% ..  (,                  . ...   .           \n' +
    //     '                                      . ,. .....   .&&&. ..,. *                 .  ..   ..          \n' +
    //     '                                            * ..*. %%&   .    ..               ..,  ,,. ...         \n' +
    //     '         .  ..   ...                   . .  ,.,  .(/.     ,  .*.                . .      ..         \n' +
    //     '                  .                               .%.     ,   .                 .,,. .,.  ..        \n' +
    //     '        .    ..   ..                    ..,  ....  #      *.  .*.                  .                \n' +
    //     '                   ..                   . .       ..      .                       ,.  ,.  .,.       \n' +
    //     '        ...  ..    .                               ,                                                \n' +
    //     '                                                   ,       .    .                   .   .   .       \n' +
    //     'Design by HĀMƏĐ                                                   ,                                 \n')
    //
    // console.log('╔═╦═╗\n' + '╠═╬═╣\n' + '╚═╩═╝')
    // ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░save░░░░░░░░░

    const errorS = (e) => toast.error(e);
    const sendToServer = () => {
        // console.log(information.name, information.family, FixNumbers(information.code), FixNumbers(information.tel), information.birthday.year + '/' + information.birthday.month + '/' + information.birthday.day)
        if (!!information.name && !!information.family && !!information.tel && (!!information.code && information.code > 9)) {
            if (!checkBox) {
                errorS(' قوانین و مقرارت سایت پذیرفته نشده است.')
                return
            }
            setLoad(true)
            formData = new FormData();
            formData.append("FirstName", information.name);
            formData.append("LastName", information.family);
            formData.append("NationalId", FixNumbers(information.code));
            // formData.append("birthdate", information.birthday.year + '/' + information.birthday.month + '/' + information.birthday.day);
            formData.append("phoneNumber", FixNumbers(information.tel));
            // formData.append("NationalIdImage", file);
            RegisterApi(formData).then((response) => {
                if (response.status === 200) {
                    setLoad(false)
                    setError('')
                    logEvent(analytics, 'register-chineh');
                    history.push({pathname: '/otp', state: {tel: information.tel, type: 'login'}})
                } else {
                    setLoad(false)
                    setError(!!response.validationResult.errors[0] ? response.validationResult.errors[0].errorMessage : 'اطلاعات خود را به درستی وارد کنید')
                }
            }).catch(() => {
                setLoad(false)
                setError('مشکل اتصال به سرور')
            })
        } else {
            setLoad(false)
            setError('لطفا اطلاعات خود را به درستی وارد کنید')
        }

    }
    return (
        <div className='register'>

            {
                rol &&
                <div className="register-box">
                    <div className="dark-menu"></div>
                    <div className="register-message">
                        <img width={20} src={Delete} onClick={() => setRol(false)} alt=""/>
                        <p>
                            کاربر گرامی لطفاً موارد زیر را جهت استفاده بهینه از خدمات و برنامه‌‏های کاربردی چینه به دقت
                            ملاحظه فرمایید.
                            ورود کاربران به وب‏‌سایت چینه هنگام استفاده از پروفایل شخصی، طرح‏‌های تشویقی، ویدئوهای رسانه
                            تصویری چینه و سایر خدمات ارائه شده توسط چینه به معنای آگاه بودن و پذیرفتن شرایط و قوانین و
                            همچنین نحوه استفاده از سرویس‌‏ها و خدمات چینه است. توجه داشته باشید که ثبت سفارش نیز در هر
                            زمان
                            به معنی پذیرفتن کامل کلیه شرایط و قوانین چینه از سوی کاربر است.
                            لازم به ذکر است شرایط و قوانین مندرج، جایگزین کلیه توافق‏‌های قبلی محسوب می‏‌شود.

                        </p>

                        <p>
                            قوانین عمومی
                        </p>
                        توجه داشته باشید کلیه اصول و رویه‏‌های چینه منطبق با قوانین جمهوری اسلامی ایران، قانون تجارت
                        الکترونیک و قانون حمایت از حقوق مصرف کننده است و متعاقبا کاربر نیز موظف به رعایت قوانین مرتبط با
                        کاربر است. در صورتی که در قوانین مندرج، رویه‏‌ها و سرویس‏‌های چینه تغییراتی در آینده ایجاد شود،
                        در
                        همین صفحه منتشر و به روز رسانی می شود و شما توافق می‏‌کنید که استفاده مستمر شما از سایت به معنی
                        پذیرش هرگونه تغییر است.
                        <p>

                            تعریف مشتری یا کاربر

                        </p>

                        مشتری یا کاربر به شخصی گفته می‌شود که با اطلاعات کاربری خود که در فرم ثبت‌نام درج کرده است، به
                        ثبت
                        سفارش یا هرگونه استفاده از خدمات چینه اقدام کند.
                        همچنین از آنجا که چینه یک وب‌سایت خرده‌فروشی آنلاین است، طبق قانون تجارت الکترونیک مشتری یا مصرف
                        کننده هر شخصی است که به منظوری جز تجارت یا شغل حرفه‌ای به خرید کالا یا خدمات اقدام می‌کند.

                        <p>

                            تعریف فروشنده و شرایط کالاهای فروشندگان
                        </p>

                        <p>
                            محصولات ارایه شده در وب‌سایت، یا توسط چینه از شرکت‌های معتبر و واردکنندگان رسمی و قانونی
                            خریداری
                            و عرضه می‌شود و یا برخی فروشندگان با چینه همکاری می‌کنند و محصولات خود را به‌واسطه این
                            فروشگاه
                            اینترنتی، به صورت آنلاین به فروش می‌رسانند. منظور از کالای فروشندگان، گروه دوم است و
                            فروشنده،
                            شخصی حقیقی یا حقوقی است که محصول خود را در وب‌سایت چینه عرضه کرده، به فروش می رساند. همچنین
                            اعتبار فروشنده‌ها توسط چینه بررسی و مدارک مورد نیاز از ایشان اخذ می‌شود..
                        </p>

                        <p>
                            - خریداران حداکثر تا 2 روز بعد از ثبت سفارش و قطعی شدن آن، فرصت دارند درخواست ارسال فاکتور
                            را
                            ثبت کنند.
                            - 1 روز کاری فاصله زمانی تحویل برای کالاهای فروشندگان، به معنای این است که در صورت خرید
                            کالای
                            فروشندگان ، شروع پردازش سفارش حداکثر 1 روز پس از زمان سفارش‌گذاری در چینه است.
                            - منظور از ۱ روز کاری، زمان آماده‌سازی و ارسال کالا به انبار چینه توسط فروشنده است، که امکان
                            دارد این زمان بسته به نوع کالا تغییر یابد.
                            - در صورت بروز تاخیر در پردازش سفارش شما، این موضوع از طریق پیامک به شما اطلاع رسانی شده و
                            چینه
                            تلاش خواهد کرد محصول/محصولات مورد نظر شما را ظرف ۲۴ ساعت تامین کند، درصورت عدم تامین کالا
                            بعد از
                            ۲۴ ساعت، اقلام در انتظار تامین سفارش شما بصورت سیستمی لغو خواهد شد.
                            - در شرایط خاص مثل فروش شگفت‌انگیز، احتمال لغو شدن سفارش مربوط به محصولات فروشندگان به
                            دلایلی
                            مانند اتمام موجودی کالا وجود دارد و چینه مجاز است بدون اطلاع قبلی نسبت به توقف سفارش‌‏گیری
                            جدید،
                            اقدام و فروش را متوقف کند.

                        </p>

                        <p>
                            ارتباطات الکترونیکی
                        </p>

                        <p>
                            هنگامی که شما از سرویس‌‏ها و خدمات چینه استفاده می‏‌کنید، سفارش اینترنتی خود را ثبت یا خرید
                            می‏‌کنید و یا به چینه ایمیل می‏‌زنید، این ارتباطات به صورت الکترونیکی انجام می‏‌شود و در
                            صورتی
                            که درخواست شما با رعایت کلیه اصول و رویه‏‌ها باشد، شما موافقت می‌‏کنید که چینه به صورت
                            الکترونیکی (از طریق پست الکترونیکی، سرویس پیام کوتاه و سایر سرویس‌های الکترونیکی) به درخواست
                            شما
                            پاسخ دهد.
                        </p>

                        <p>
                            همچنین آدرس ایمیل و تلفن‌هایی که مشتری در پروفایل خود ثبت می‌کند، تنها آدرس ایمیل و تلفن‌های
                            رسمی و مورد تایید مشتری است و تمام مکاتبات و پاسخ‌های شرکت از طریق آنها صورت می‌گیرد.
                        </p>

                        <p>
                            جهت اطلاع‌رسانی رویدادها، خدمات و سرویس‌های ویژه یا پروموشن‌ها، امکان دارد چینه برای اعضای
                            وب
                            سایت ایمیل یا پیامک ارسال نماید. در صورتی که کاربران تمایل به دریافت اینگونه ایمیل و
                            پیامک‌ها
                            نداشته باشند، می‌توانند عضویت دریافت خبرنامه چینه را در پروفایل خود لغو کنند.
                        </p>
                        <p>
                            توجه داشته باشید که ....... تنها شماره‌‌هایی است که چینه از طریق آن برای کاربران و مشتریان
                            خود
                            پیامک (اس ام اس) ارسال می‌کند. همچنین این شماره‌‌ها سامانه ارسال پیامک است که وضعیت پردازش
                            سفارش
                            یا رویدادها، خدمات و سرویس‌های ویژه چینه را به اطلاع کاربران می‌رساند و روشن است که امکان
                            دریافت
                            پیام‌های شما از طریق آن وجود ندارد.
                        </p>

                        <p>
                            همچنین ممکن است چینه از طریق شماره ...... برای برخی کاربران یا مشتریان خود، سوال نظرسنجی
                            ارسال
                            کند.
                            بنابراین ارسال هرگونه پیامک تحت عنوان چینه، با هر شماره دیگری تخلف و سوء استفاده از نام چینه
                            است
                            و در صورت دریافت چنین پیامکی، لطفاً جهت پیگیری قانونی آن را به Info@Chineh.com اطلاع دهید.

                        </p>


                        <p>
                            سیاست‌های رعایت حریم شخصی
                        </p>


                        <p>
                            چینه به اطلاعات خصوصی اشخاصى که از خدمات سایت استفاده می‏‌کنند، احترام گذاشته و از آن محافظت
                            می‏‌کند.
                            چینه متعهد می‏‌شود در حد توان از حریم شخصی شما دفاع کند و در این راستا، تکنولوژی مورد نیاز
                            برای
                            هرچه مطمئن‏‌تر و امن‏‌تر شدن استفاده شما از سایت را، توسعه دهد. در واقع با استفاده از سایت
                            چینه،
                            شما رضایت خود را از این سیاست نشان می‏‌دهید.

                        </p>


                        <p>
                            همه مطالب در دسترس از طریق هر یک از خدمات چینه، مانند متن، گرافیک، آرم، آیکون دکمه، تصاویر،
                            ویدئوهای تصویری، موارد قابل دانلود و کپی، داده‌ها و کلیه محتوای تولید شده توسط چینه جزئی از
                            اموال چینه محسوب می‏‌شود و حق استفاده و نشر تمامی مطالب موجود و در دسترس در انحصار چینه است
                            و
                            هرگونه استفاده بدون کسب مجوز کتبی، حق پیگرد قانونی را برای چینه محفوظ می‏‌دارد.
                        </p>


                        <p>
                            علاوه بر این، اسکریپت‌ها، و اسامی خدمات قابل ارائه از طریق هر یک از خدمات ایجاد شده توسط
                            چینه و
                            علائم تجاری ثبت شده نیز در انحصار چینه است و هر گونه استفاده با مقاصد تجاری پیگرد قانونی
                            دارد.
                            کاربران مجاز به بهره‌‏برداری و استفاده از لیست محصولات، مشخصات فنی، قیمت و هر گونه استفاده
                            از
                            مشتقات وب‏‌سایت چینه و یا هر یک از خدمات و یا مطالب، دانلود یا کپی کردن اطلاعات با مقاصد
                            تجاری،
                            هر گونه استفاده از داده کاوی، روبات، یا روش‌‏های مشابه مانند جمع آوری داده‌‏ها و ابزارهای
                            استخراج نیستند و کلیه این حقوق به صراحت برای چینه محفوظ است. در صورت استفاده از هر یک از
                            خدمات
                            چینه، کاربران مسئول حفظ محرمانه بودن حساب و رمز عبور خود هستند و تمامی مسئولیت فعالیت‌‏هایی
                            که
                            تحت حساب کاربری و یا رمز ورود انجام می‏‌پذیرد به عهده کاربران است. چینه محصولاتی مناسب
                            استفاده
                            افراد زیر 18 سال به فروش می‏‌رساند و در صورتی که کاربران از سن ذکر شده جوان‌‏تر هستند
                            می‌‏باید
                            با اطلاع والدین و یا قیم قانونی، به خرید و پرداخت اقدام کنند.
                        </p>

                        <p>
                            تنها مرجع رسمی مورد تایید ما برای ارتباط با شما، پایگاه رسمی این سایت یعنی www.chineh.ir
                            است. ما
                            با هیچ روش دیگری جز ارسال نامه از طرف آدرس‏‌های رسمی و تایید شده در سایت، با شما تماس
                            نمی‌‏گیریم. وب سایت چینه هیچگونه سایت اینترنتی با آدرسی غیر از www.chineh.ir و همچنین، هیچ
                            گونه
                            وبلاگ و شناسه در برنامه‏‌های گفتگوی اینترنتی همچون Yahoo Messenger یا MSN Messenger و غیره
                            ندارد
                            و هیچ ‏گاه با این روش‏‌ها با شما تماس نمی‏‌گیرد. کاربران جهت برقراری ارتباط، تنها می‏‌توانند
                            از
                            آدرس‌‏های ذکر شده در بخش ارتباط با ما استفاده کنند.
                        </p>
                        <p>
                            ثبت، پردازش و ارسال سفارش
                        </p>

                        <p>
                            - روز کاری به معنی روز شنبه تا پنج شنبه هر هفته، به استثنای تعطیلات عمومی در ایران است و
                            کلیه
                            سفارش‏‌های ثبت شده در طول روزهای کاری و اولین روز پس از تعطیلات پردازش می‌‏شوند. چینه به
                            مشتریان
                            خود در 7 روز هفته و 24 ساعت در روز امکان سفارش‌‏گذاری می‌‏دهد.
                        </p>
                        <p>
                            - کلیه سفارش‌‏های ثبت شده در سایت چینه به وسیله ارسال کد سفارش از طریق پیام کوتاه و پیش
                            فاکتور
                            از طریق ایمیل، در صف پردازش قرار می‏‌گیرند. چینه همواره در ارسال و تحویل کلیه سفارش‌‏های ثبت
                            شده، نهایت دقت و تلاش خود را انجام می‌دهد. با وجود این، در صورتی که موجودی محصولی در چینه به
                            پایان برسد، حتی پس از اقدام مشتری به سفارش‌‏گذاری، حق کنسل کردن آن سفارش و یا استرداد وجه
                            سفارش
                            برای چینه محفوظ است و یا مشتری می‏‌تواند به جای کالای به اتمام رسیده، محصول دیگری را جایگزین
                            کند.
                            - در صورت بروز مشکل در پردازش نهایی سبد خرید مانند اتمام موجودی کالا یا انصراف مشتری، مبلغ
                            پرداخت شده طی 24 الی 48 ساعت کاری به حساب مشتری واریز خواهد شد.

                        </p>

                        <p>
                            - چینه مجاز است بدون اطلاع قبلی نسبت به توقف سفارش‌‏گیری جدید، اقدام و فروش خود را متوقف کند
                            و
                            کلیه سفارش‌‏های ثبت شده قبل از توقف سفارش‌‏گیری، پردازش و ارسال می‌‏شود. حق قطع فروش کلیه و
                            یا
                            بخشی از محصولات به هر دلیلی مانند اتمام موجودی کالا بدون اطلاع قبلی، برای چینه محفوظ است.
                            - در صورت بروز هرگونه خطا نسبت به درج قیمت و ارزش ریالی کالاهای موجود در سایت چینه، حق بلا
                            اثر
                            نمودن سفارش و خرید انجام شده توسط مشتری، برای چینه محفوظ است. چینه در اسرع وقت وجوه دریافتی
                            را
                            طی 24 الی 48 ساعت کاری به حساب اعلام شده توسط مشتری واریز و عودت می‌نماید و مشتری با ورود به
                            سایت چینه می‌پذیرد از این امر آگاهی داشته و در این خصوص ادعایی نخواهد داشت.

                        </p>
                        <p>
                            - کاربران باید هنگام سفارش کالای مورد نظر خود، فرم سفارش را با اطلاعات صحیح و به طور کامل
                            تکمیل
                            کنند. بدیهی است درصورت ورود اطلاعات ناقص یا نادرست، سفارش کاربر قابل پیگیری و تحویل نخواهد
                            بود.
                            بنابراین درج آدرس، ایمیل و شماره تماس‌های همراه و ثابت توسط مشتری، به منزله مورد تایید بودن
                            صحت
                            آنها است و در صورتی که این موارد به صورت صحیح یا کامل درج نشده باشد، چینه جهت اطمینان از صحت
                            و
                            قطعیت ثبت سفارش می‌تواند از مشتری، اطلاعات تکمیلی و بیشتری درخواست کند.همچنین، مشتریان
                            می‌توانند
                            نام، آدرس و تلفن شخص دیگری را برای تحویل گرفتن سفارش وارد کنند و اگر مبلغ سفارش از پیش
                            پرداخت
                            شده باشد، تحویل گیرنده سفارش هنگام دریافت کالا باید کارت شناسایی همراه داشته باشد.
                        </p>
                        <p>
                            - کاربری هر مشتری شامل نام و نام خانوادگی باید با اطلاعات مدارک شناسایی مطابقت داشته باشد و
                            در
                            صورت عدم تطابق، فروشگاه‌‌های اینترنتی مجاز به ارائه خدمات به این گروه از مشتریان نخواهد بود.
                            لذا
                            خریداران محترم می بایست با ارائه تصویر کارت ملی خود و خرید از طریق کارت بانکی متعلق به شخص
                            خریدار که با هویت سفارش دهنده در تطابق می باشد، اقدام به ثبت سفارش نمایند.
                            لازم به ذکر است که این امر به منظور جلوگیری از سواستفاده‌های احتمالی و مواردی نظیر جلوگیری
                            از
                            خرید با کارت‌های سرقت شده و موارد مشابه در نظر گرفته شده است.

                        </p>
                        <p>
                            - با توجه به ثبت سیستمی سفارش، به هیچ عنوان امکان صدور فاکتور مجدد یا تغییر مشخصات آن از
                            جمله
                            تغییر فاکتوری که به نام شخص حقیقی صادر شده، به نام شخص حقوقی وجود ندارد. بنابراین لازم است
                            مشتریان هنگام ثبت سفارش، نسبت به این مساله دقت لازم را داشته باشند و اگر نیاز دارند که
                            فاکتور به
                            نام حقوقی برای آنها صادر شود، هنگام ثبت سفارش خود، با انتخاب گزینه " تکمیل اطلاعات حقوقی " و
                            وارد کردن مشخصات سازمان موردنظر، برای خرید سازمانی و دریافت فاکتور رسمی اقدام کنند.
                        </p>
                        <p>
                            همچنین آدرسی که خریدار به عنوان آدرس تحویل‌گیرنده ثبت یا انتخاب می‌کند، در فاکتور درج خواهد
                            شد و
                            لازم است درخواست کنندگان فاکتور به نام شخص حقوقی هنگام ثبت سفارش به این نکته توجه کافی داشته
                            باشند، چرا که تغییر آدرس درج شده روی فاکتور پس از پردازش و تایید سفارش، به هیچ عنوان
                            امکان‌پذیر
                            نخواهد بود.
                        </p>
                        <p>
                            - امکان پرداخت در محل برای سفارش‌هایی که از طریق باربری ارسال می‌شوند یا مبلغ آنها بیشتر از
                            صد
                            میلیون ریال است، وجود ندارد و لازم است پیش از ارسال، مبلغ اینگونه سفارش‌ها از طریق پرداخت
                            کارت
                            به کارت یا پرداخت اینترنتی تسویه شود.
                        </p>
                        <p>
                            - از آنجا که چینه یک وب‌سایت خرده‌فروشی آنلاین است، سفارش یک کالا به تعداد بالای 10 عدد،
                            مغایر
                            با هدف مصرف خریدار است، در صورت ثبت این مورد و یا سفارشاتی که با تعداد اقلام بالایی همراه
                            هستند،
                            لازم است پیش از ارسال، سفارش مشتریان ابتدا توسط چینه بررسی و در صورت تایید، پردازش گردد. در
                            این
                            موارد پرداخت وجه و تسویه، قبل از ارسال کالا الزامی است؛ درغیر اینصورت سفارشات با هماهنگی
                            مشتری
                            کنسل شده و در صورت واریز وجه، مبلغ پرداخت شده طی 24 الی 48 ساعت کاری به حساب مشتری عودت داده
                            خواهد شد.
                        </p>
                        <p>
                            - تحویل سفارش در اماکن عمومی همچون کافه، کافی نت، رستوران، هتل و مانند آن امکان‌پذیر نیست و
                            لازم
                            است آدرس تحویل، دقیق و قابل استناد باشد
                        </p>
                        <p>
                            - در رابطه با سرویس تحویل اکسپرس فروشگاه اینترنتی چینه، سفارش درب اصلی ساختمان (منتهی به
                            خیابان)
                            تحویل داده می‌شود.
                            - لازم به ذکر است افزودن کالا به سبد خرید به معنی رزرو کالا نیست و هیچ گونه حقی را برای
                            مشتریان
                            ایجاد نمی‌کند. همچنین تا پیش از ثبت نهایی، هرگونه تغییر از جمله تغییر در موجودی کالا یا
                            قیمت،
                            روی کالای افزوده شده به سبد خرید اعمال خواهد شد. بنابراین به مشتریانی که تمایل و تصمیم به
                            خرید
                            قطعی دارند، به‌ویژه درباره کالاهای ارائه شده تحت عنوان شگفت‌انگیز که دارای محدودیت تعداد
                            هستند،
                            توصیه می‌شود در اسرع وقت سفارش خود را نهایی کنند تا با اتمام موجودی یا تغییر قیمتی کالاها
                            روبرو
                            نشوند. شایان ذکر است سفارش تنها زمانی نهایی می‌شود که کاربران کد سبد خرید (dkc) سفارش خود را
                            دریافت کنند و بدیهی است که چینه هیچ‌گونه مسوولیتی نسبت به کالاهایی که در سبد خرید رها شده
                            است،
                            ندارد.

                        </p>
                        <p>خسارت در هنگام حمل و نقل</p>
                        <p>
                            چینه همواره نهایت تلاش خود را می‏‌کند تا کلیه سفارش‏‌ها در نهایت صحت و بدون آسیب به دست
                            مشتریان
                            خود در سراسر کشور برسد. با توجه به بسته‌بندی ایمن و استاندارد همه مرسولات، تحویل به هر یک از
                            شرکت‌‏های حمل و نقل معتبر به انتخاب کاربر و اعلام بارنامه مرسوله (درج در سبد خرید و اطلاع
                            رسانی
                            از طریق سرویس پیام کوتاه) به این معنی است که بروز هر گونه حادثه در هنگام حمل و تحویل به عهده
                            شرکت حمل و نقل است و چینه تنها در صورت تایید شرکت حمل کننده سفارش و در راستای تسهیل امور
                            پیگیری،
                            خسارت را جبران می‌‏کند.
                        </p>
                        <p>
                            آسیب‏‌های ناشی از حمل و نقل باید در عرض 24 ساعت کاری پس از تحویل سفارش به خدمات پس از فروش
                            چینه
                            اطلاع داده شود و کالای آسیب دیده به همراه صورت جلسه رسمی شرکت حمل کننده و کلیه متعلقات و
                            فاکتور
                            به خدمات پس از فروش چینه ارسال شود.
                            برای اطلاعات بیشتر به صفحه رویه‌های بازگرداندن کالا مراجعه کنید.

                        </p>
                        <p>سرویس مهلت تست ۷ روزه چینه</p>
                        <p>
                            در راستای ایجاد سرویس‌‏های ارزش افزوده، چینه با همکاری کلیه تامین‏‌ کنندگان خود این امکان را
                            ایجاد نموده تا کلیه کالاهایی که نیاز به تست دارند، تا 7 روز پس از تحویل، دارای فرصت تست
                            باشند و
                            هر گونه نقص فنی در دستگاه شامل سرویس تعویض بدون قید و شرط و فراتر از سرویس گارانتی محصول،
                            می‏‌شود.
                        </p>
                        <p>
                            لازم به توجه است که ایرادهای اعلام شده توسط مشتری (در مواردی که مرجوعی سلیقه ای نیست)، توسط
                            کارشناسان خدمات پس از فروش چینه کنترل و بررسی می‏‌شود در صورت تائید وجود نقص فنی، کالا مشمول
                            استفاده از سرویس بازگشت وجه می‏‌شود. در غیر اینصورت در صورت تمایل مشتری کالا برای مشتری
                            دوباره
                            ارسال خواهد شد.
                        </p>
                        <p>
                            آسیب‏‌های فیزیکی ناشی از حمل و نقل برطبق رویه ها در لحظه تحویل به مشتری و یا 24 ساعت پس از
                            آن
                            تحویل کالا اعلام گردد. درغیر این صورت جهت تایید درخواست مرجوعی نیاز به ارسال مستندات شفاف می
                            باشد.
                        </p>
                        <p>
                            برای تسریع در استفاده از سرویس مرجوعی و تعویض 7 روزه چینه، دقت فرمایید که کالا به ‏خوبی
                            بسته‌بندی و در شرایط اولیه باشد، لوازم جانبی و فاکتور به صورت کامل به همراه آن ارسال شود.
                        </p>
                        <p>
                            در صورتی که موجودی کالای آسیب دیده در چینه به اتمام برسد، و چینه امکان تعویض کالای آسیب دیده
                            با
                            کالای جایگزین را نداشته باشد، می‌تواند مبلغ کالا را به حساب خریدار استرداد نماید.
                        </p>
                        <p>نظرات کاربران</p>
                        <p>
                            هدف از ایجاد بخش نظرات در چینه، اشتراک‌گذاری تجربه‌ی خرید و کاربری محصولاتی است که به فروش
                            می‌رسد. بدیهی است بخش نظرات چینه با دیگر سایت‌ها و شبکه‌های اجتماعی متفاوت است. در این بخش،
                            هر
                            کاربر مجاز است در چهارچوب شرایط و قوانین سایت، نظرات خود را به اشتراک بگذارد و پس از بررسی
                            کارشناسان تایید، نظرش را روی سایت مشاهده کند. بدیهی است که اگر قوانین سایت در نظرات کاربری
                            رعایت
                            نشود، تایید نمی‌شوند و در نتیجه در سایت به نمایش درنمی‌آیند. چینه در قبال درستی یا نادرستی
                            نظرات
                            منتشرشده در این قسمت، هیچ‌گونه مسئولیتی ندارد. نمایش نظرات کاربران در سایت به‌هیچ‌وجه به
                            معنی
                            تایید فنی چینه درباره‌ی محتویات نظر نیست؛ لذا از کاربران محترم تقاضا می‌شود، نظرات را اصل و
                            پایه‌ی انتخاب و تصمیم‌گیری خود قرار ندهند.
                        </p>
                        <p>قوه قهریه</p>
                        <p>

                        </p>
                        <p>
                            تمامی شرایط و قوانین مندرج، در شرایط عادی قابل اجرا است و در صورت بروز هرگونه از موارد قوه
                            قهریه، چینه هیچ گونه مسئولیتی ندارد.
                        </p>
                        <p>
                            چینه خود را ملزم به رعایت حریم شخصی کاربران می‌داند، لطفا در صورت مشاهده هرگونه تخلف، مراتب
                            را
                            از طریق کانال‏‌های ارتباطی ذکر شده با ما در میان بگذارید.
                        </p>


                    </div>
                </div>
            }

            <div className="title">
                <p>
                    پلتفرم خرید مستقیم تجهزات الکترونیک آسانسور
                </p>

                <div className='line-space'/>

            </div>
            <div className="box">

                <div className='line-space'/>

                <img width={80} src={Finger} alt=""/>

                <div className='line-space'/>
                <div className='line-space'/>

                <p className='text-center w-100'>
                    با وارد کردن اطلاعات خود میتوانید از امکانات چینه استفاده
                    نمایید.
                </p>

                <p className={'text-right w-100 orange-color mb-0'}> لطفا نام و نام خانوادگی خود را بصورت فارسی تایپ
                    کنید! <span className={'text-right w-100 orange-color'}>

                </span>
                </p>

                <div className='line-space'/>


                {/*<div className="box-input d-flex  flex-wrap">*/}
                <div className="form-group box-input">
                    <input id="form_name" type="text" name="name" className="form-control"
                           onChange={(e) => setInformation({...information, name: e.target.value})}
                           placeholder="نام"
                    />
                </div>
                <div className="form-group box-input">
                    <input id="form_name" type="text" name="family-name" className="form-control"
                           onChange={(e) => setInformation({...information, family: e.target.value})}
                           placeholder="نام خانوادگی"
                    />
                </div>
                {/*</div>*/}
                <div className="form-group box-input">
                    <input id="form_name" type="tel" name="tel" className="form-control"
                           onChange={(e) => setInformation({...information, tel: e.target.value})}
                           placeholder="شماره تماس"/>
                </div>

                <div className="form-group box-input">
                    <input id="form_name" type="tel" name="NationalId" className="form-control"
                           onChange={(e) => setInformation({...information, code: e.target.value})}
                           placeholder="کد ملی"/>
                </div>

                {/*<div className="form-group box-input">*/}
                {/*    /!*<input id="form_name" type="tel" name="birthday" className="form-control"*!/*/}
                {/*    /!*       onChange={(e) => setInformation({...information, birthday: e.target.value})}*!/*/}
                {/*    /!*       placeholder="تاریخ تولد (1400/05/01)"/>*!/*/}
                {/*    <DatePicker*/}
                {/*        inputClassName={'form-control'}*/}
                {/*        value={information.birthday}*/}
                {/*        onChange={(e) => setInformation({...information, birthday: e})}*/}
                {/*        inputPlaceholder="تاریخ تولد"*/}
                {/*        locale="fa"*/}
                {/*        shouldHighlightWeekends*/}
                {/*    />*/}

                {/*</div>*/}


                {/*<label htmlFor="meli" className='upload'>*/}
                {/*    <span className={!!file ? 'upload-active' : ''}>*/}
                {/*        {*/}
                {/*            !!file*/}
                {/*            ?*/}
                {/*                'کارت شناسایی آپلود شد'*/}
                {/*                :*/}
                {/*                'آپلود کارت شناسایی'*/}
                {/*        }*/}

                {/*    </span>*/}
                {/*    <input type="file" id='meli' accept="image/*" onChange={(e) => {*/}
                {/*        setFile(e.target.files[0])*/}
                {/*    }} hidden/>*/}
                {/*</label>*/}

                <div className="line-space"/>
                <ErrorAlert text={error}/>
                <p className='d-flex align-items-center'>
                    <input type="checkbox" id="accept" name="accept" onClick={() => setCheckBox(!checkBox)}
                           value="accept"/>
                    <span className='mr-2 blue-color  ' onClick={() => setRol(true)}>قوانین و مقرارت سایت </span>
                    <span> چینه را میپذیرم</span>

                </p>
                <div className={"bottom " + (!load && checkBox ? '' : 'no-active')}
                     onClick={() => !load && sendToServer()}>
                    <span>{load ? 'لطفا کمی صبر کنید' : 'ورود به سامانه چینه'}</span></div>

                <p className={'text-right w-100 orange-color'}>
                    چینه نسبت به اطلاعات شما رازدار و متعهد است از اطلاعات وارد شده، جز برای استفاده از خدمات، هیچ
                    استفاده‌ی دیگری نکند.
                </p>
                <p className='align-items-center text-justify'>
                    در صورتی که ثبت نام کرده اید
                    <span className='pr-1 orange-color pl-1 cursor-p underLine'
                          onClick={() => history.push('login')}>اینجا</span>
                    کلیک کنید.
                </p>
            </div>
        </div>
    );
};

export default Register;
