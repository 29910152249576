import React, {useEffect, useRef, useState} from 'react';
import {DeleteItemAdmin, GetAdminOrders, GetEx, InrepresentativeAdmin, RepresentativeId, Separator, Type} from "../api";
import {toast} from "react-toastify";

let type = '';
const Admin = () => {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const select = useRef(null)
    const notify = (e) => toast.success(e);
    const error = (e) => toast.error(e);

    const selector = () => {
        console.log(select.current.value)
        if (select.current.value !== type) {
            setFilter(select.current.value)
            if (select.current.value) {
                type = select.current.value
            } else {
                type = 'delivered'
            }

        }
    }

    function download(blob, filename) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    const excel = () => {
        GetEx().then((r) => {
            download(r, 'chineh.csv')
            console.log(r, 'yyy')
        })
    }
    useEffect(() => {
        Type().then((r) => {
            console.log(r)
        })
        GetAdminOrders(type).then((response) => {
            if (response.status === 200) {
                setData(response.data)
            }
            console.log(response)
        })
    }, [])
    const sendToServer = (d, s, id) => {
        if ((d === 'cip' || d === 'fca') && s === 'successfulpayment') {
            InrepresentativeAdmin(id, {representativeId: RepresentativeId}).then((response) => {
                if (response.status === 200 && response.validationResult.errors.length === 0) {
                    notify('ثبت شد')
                    GetAdminOrders().then((response) => {
                        if (response.status === 200) {
                            setData(response.data)
                        }
                        console.log(response)
                    })
                } else if (response.validationResult.errors.length > 0) {
                    error('مشکل در اتصال به اینترنت ')
                } else {
                    error('مشکل در ارتباط با سرور')
                }
            })
        } else if (d === 'exw' && s === 'successfulpayment') {

        } else if (s === 'unsuccessfulpayment') {

        }

    }
    const deleteItem = (id) => {
        DeleteItemAdmin(id).then((response) => {
            if (response.status === 200) {
                notify('کنسل شد')
                GetAdminOrders().then((response) => {
                    if (response.status === 200) {
                        setData(response.data)
                    }
                    console.log(response)
                })
            }
        })
    }
    const StateButton = (v) => {
        if (!!v.licence) {
            switch (v.licence.status) {
                case "editable":
                    return 'ارسال اطلاعات';
                    break;
                case "accepted":
                    return 'تایید شده';
                    break;
                case "awaitingvalidation":
                    return 'در حال بررسی';
                    break;
                case "rejected":
                    return 'رد شده';
                    break;
                case "new":
                    return 'ارسال اطلاعات';
                    break;
                default:
                    return '';
            }
        } else if (!!v.product && v.product.type === "ControlPanel") {
            return 'ارسال اطلاعات';
        } else if (!!v.product && v.product.type === "UninterruptiblePowerSupply") {
            return 'UPS';
        }

    }

    const StateButtonAdmin = (d, s) => {
        if ((d === 'fca' || d === 'cip') && s === 'successfulpayment') {
            return 'تحویل به نماینده'
        } else if (d === 'exw' && s === 'successfulpayment') {
            return 'تحویل درب کارخانه'
        } else if (s === 'unsuccessfulpayment' || s === 'submitted') {
            return 'پرداخت نشده'
        } else if (s === 'inrepresentativestock') {
            return 'موجود در انبار نماینده'
        } else if (s === 'cancelled') {
            return 'کنسل شد'
        } else if (s === 'delivered') {
            return 'تحویل شد'
        } else {
            return ''
        }
    }
    return (<div className={'admin project-agent'}>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="title w-100">
                <h5 className={'d-flex justify-content-between align-items-center'}> پنل مدیریت <span onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                }}>خروج از حساب کاربری</span>
                </h5>
            </div>
            <div className="d-flex w-75 align-self-center justify-content-around" style={{height: 45}}>
                <select name="" id="select" ref={select} onClick={() => selector()}>
                    <option value="delivered">تحویل داده شده</option>
                    <option value="successfulpayment">پرداخت موفق</option>
                    <option value="inrepresentativestock">تحویل نماینده</option>
                    <option value="unsuccessfulpayment">پرداخت نشده</option>
                    <option value="cancelled">کنسل شده</option>
                    <option value="" selected="selected">همه</option>
                </select>
                {/*<a href="https://chineh.ir/chinehapi/api/v1/reports/orders">*/}
                <div onClick={() => excel()} className="bottom">خروجی اکسل</div>
                {/*</a>*/}
            </div>
            <div className="line-space"/>
            <div className="line-space"/>
            <div className="line-space"/>
            {!!data && data.length > 0 && data.filter(e => e.status === filter || filter === '').map((v, index) => {
                return <div key={index} className="admin-order mb-2">
                    {(v.status === 'drafted' || v.status === 'submitted' || v.status === 'unsuccessfulpayment' || v.status === 'successfulpayment') &&

                        <span className={'cursor-p pr-1 blue-color'} onClick={() => deleteItem(v.id)}>X</span>}

                    {v.orderItems.map((val, i) => {
                        return <div key={i} className="">
                            <div className="d-flex  p-2 justify-content-between flex-wrap">
                                <span>{val.product.type}</span>
                                <p className={'pl-3 mb-0'}>
                                    {/*<span>وضعیت سفارش  :  </span>*/}
                                    {/*<span className='fs-8 mb-0'> {StateButton(val)} </span>*/}

                                    {Separator((1 - val.discount / 100) * val.unitPrice) + '  ×  ' + val.units}

                                </p>
                            </div>
                        </div>
                    })}

                    <p className={'mb-0 mt-3 p-2 d-flex justify-content-between flex-wrap'}>
                        {/*<p>{!!v.successfulPaymentDate ? v.successfulPaymentDate : ''}</p>*/}
                        <span>{!!v.successfulPaymentDate ? v.successfulPaymentDate : ''}</span>
                        <span
                            className='fs-9 mb-0 '>  {!!v.address ? v.address.line : (v.deliveryType === 'cip' ? 'تحویل از نمایندگی' : v.deliveryType === 'exw' ? 'تحویل از کارخانه' : '')} </span>
                        <span>
                              {v.requireFormalInvoice ? 'فاکتور رسمی میخوام' : 'فاکتور رسمی نمیخوام'}
                          </span>
                    </p>
                    <p className={'d-flex justify-content-between p-2 flex-wrap'}>
                        <span>{v.customer.firstName + ' ' + v.customer.lastName}</span>
                        <span>{v.customer.phoneNumber}</span>
                    </p>
                    {/*<p className=' mt-1 '>*/}
                    {/*    <span>{v.deliveryType === 'fca' ? 'آدرس    : ' : ''}</span>*/}
                    {/*    {!!v.address ? v.address.line : ''}*/}

                    {/*</p>*/}
                    <div
                        className={"bottom mb-0 w-25 align-self-center " + ((v.status === 'successfulpayment' && (v.deliveryType === 'cip' || v.deliveryType === 'fca')) ? '' : v.status !== 'delivered' && 'button-noChange ') + (v.status === 'delivered' ? ' button-green ' : '')}>
                        <span
                            onClick={() => sendToServer(v.deliveryType, v.status, v.id)}>{StateButtonAdmin(v.deliveryType, v.status)}</span>
                    </div>
                </div>
            })}
        </div>);
};

export default Admin;
