import React, { useEffect } from "react";
import { HashRouter, Link, Route, useHistory } from "react-router-dom";
import Main from "./view/main";
import Register from "./view/register";
import Authentication from "./view/authentication";
import Otp from "./view/otp";
import ProjectRepresentative from "./view/projectRepresentative";
import Complete from "./view/Complete";
import AddProject from "./view/addProject";
import Login from "./view/login";
import Payment from "./view/payment";
import Product from "./view/product";
import { useDispatch, useSelector } from "react-redux";
import CheckInformation from "./view/checkInformation";
import Menu from "./menu";
import AllOrders from "./view/allOrders";
import ShoppingList from "./view/shoppingList";
import Expectation from "./view/expectation";
import Confirmation from "./view/confirmation";
import Historys from "./view/history";
import Profile from "./view/profile";
import insta from "./assets/img/SVG/insta.svg";
import telegram from "./assets/img/SVG/telagram.svg";
import datis from "./assets/img/SVG/datis.svg";
import stars from "./assets/img/SVG/stars.svg";
import Sep from "./assets/img/sep.png";
import Logos from "./assets/img/1x/PNG/Asset 1.png";
import { Platform } from "./api";
import MenuAgent from "./view/menuAgent";
import HistoryRepresentative from "./view/historyRepresentative";
import Admin from "./view/admin";
import MenuAdmin from "./view/menuAdmin";
import AdminSerial from "./view/adminSerial";
import { getAnalytics, logEvent } from "firebase/analytics";
import UserAdmin from "./view/userAdmin";
import SuccessfullyPayment from "./view/successfullPayment";

const listOfPages = ["/register", "/login", "/otp", "/"];
const listOfDashboard = [
  "/dashboard/addProject",
  "/dashboard/payment",
  "/dashboard/product",
  "/dashboard/allOrders",
  "/dashboard/shoppingList",
  "/dashboard/expectation",
  "/dashboard/history",
  "/dashboard/checkInformation",
  "/dashboard/profile",
];
const listOfAgent = ["/agent/home", "/agent/history"];
const listOfAdmin = ["/admin/history", "/admin/history"];

const Router = () => {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = getAnalytics();
  useEffect(() => {
    logEvent(analytics, history.location.pathname);
    if (history.location.pathname === "/dashboard/product") {
      dispatch({
        type: "url",
        url: false,
      });
    } else {
      dispatch({
        type: "url",
        url: true,
      });
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (!data.startApp && history.location.pathname !== "/") {
      history.replace("/login");
    }
    if (
      listOfPages.indexOf(history.location.pathname) === -1 &&
      listOfDashboard.indexOf(history.location.pathname) <= -1 &&
      data.startApp === true &&
      !!data.login &&
      data.login.type === "Customer"
    ) {
      history.replace("/dashboard/allOrders");
    } else if (
      listOfPages.indexOf(history.location.pathname) === -1 &&
      listOfAgent.indexOf(history.location.pathname) <= -1 &&
      data.startApp === true &&
      !!data.login &&
      data.login.type === "Representative"
    ) {
      history.replace("/agent/home");
    } else if (
      listOfPages.indexOf(history.location.pathname) === -1 &&
      listOfAdmin.indexOf(history.location.pathname) <= -1 &&
      data.startApp === true &&
      !!data.login &&
      data.login.type === "Admin"
    ) {
      history.replace("/admin/home");
    } else if (!!data.login && data.login.type === "AppUser") {
      history.replace("/");
    }
  }, []);

  return (
    <HashRouter>
      <Route path="/" component={Main} exact />
      {!data.startApp ? (
        <>
          <Route path="/register" component={Register} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/otp" component={Otp} exact />
        </>
      ) : (
        <>
          {data.login.type === "Customer" ? (
            <Route path="/dashboard">
              <div className="main">
                {/*<div className="support">*/}
                {/*    <a href='https://api.whatsapp.com/send?phone=989394237860&text=' target={'_blank'} className={'orange-color'}>*/}
                {/*    <img src={WhatsApp} alt=""/>*/}
                {/*    </a>*/}
                {/*</div>*/}
                <Menu />
                <div className="dark-menu d-n"></div>
                <div className="left">
                  <button
                    className="menu"
                    id="menu"
                    onClick={() => {
                      document
                        .querySelector(".dark-menu")
                        .classList.toggle("d-n");
                      document
                        .querySelector("#menu")
                        .classList.toggle("opened");
                      document
                        .querySelector("#root")
                        .classList.toggle("no-scroll");
                      document
                        .querySelector(".main .right")
                        .classList.toggle("open");
                      document
                        .querySelector("#menu")
                        .setAttribute("aria-expanded", "true");
                    }}
                    aria-label="Main Menu"
                  >
                    <svg width="50" height="50" viewBox="0 0 100 100">
                      <path
                        className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                      />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path
                        className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                      />
                    </svg>
                  </button>
                  <Route
                    path="/dashboard/complete"
                    component={Complete}
                    exact
                  />
                  <Route
                    path="/dashboard/authentication"
                    component={Authentication}
                    exact
                  />
                  <Route
                    path="/dashboard/addProject"
                    component={AddProject}
                    exact
                  />
                  <Route path="/dashboard/payment" component={Payment} exact />
                  <Route path="/dashboard/product" component={Product} exact />
                  <Route
                    path="/dashboard/allOrders"
                    component={AllOrders}
                    exact
                  />
                  <Route
                    path="/dashboard/shoppingList"
                    component={ShoppingList}
                    exact
                  />
                  <Route
                    path="/dashboard/expectation"
                    component={Expectation}
                    exact
                  />
                  <Route
                    path="/dashboard/confirmation"
                    component={Confirmation}
                    exact
                  />
                  <Route path="/dashboard/history" component={Historys} exact />
                  <Route
                    path="/dashboard/SuccessfullyPayment"
                    component={SuccessfullyPayment}
                    exact
                  />
                  <Route
                    path="/dashboard/checkInformation"
                    component={CheckInformation}
                    exact
                  />
                  <Route path="/dashboard/profile" component={Profile} />
                </div>
              </div>
              <footer
                style={{ backgroundColor: "#f0f0f0", marginTop: 70 }}
                className="home-3"
              >
                <div className="container">
                  <div className="line-space" />
                  <div className="line-space" />
                  <div className="line-space" />
                  <div className="row ">
                    <div className="col-md-4 justify-content-center d-flex ">
                      <div className="line-space" />
                      <div className="line-space" />
                      <span className="text-white d-flex flex-column justify-content-between align-items-center order-last h-75">
                        <img
                          width={150}
                          src={Logos}
                          alt=""
                          className={"mb-4"}
                        />
                        <div className="line-space" />
                        <div className="line-space" />
                        <a
                          referrerPolicy="origin"
                          target="_blank"
                          href="https://trustseal.enamad.ir/?id=229605&amp;Code=OJhGMG74V5hs7i9y0Jbx"
                        >
                          <img
                            referrerPolicy="origin"
                            // width={200}
                            src="https://Trustseal.eNamad.ir/logo.aspx?id=229605&amp;Code=OJhGMG74V5hs7i9y0Jbx"
                            alt=""
                            style={{ cursor: "pointer" }}
                            id="OJhGMG74V5hs7i9y0Jbx"
                          />
                          {/*<img width={200} src={ENamad} alt=""/>*/}
                        </a>

                        <a target="_blank" href="https://sep.ir">
                          <img
                            width={100}
                            src={Sep}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                          {/*<img width={200} src={ENamad} alt=""/>*/}
                        </a>
                        {/*<img width={200} src={ENamad} alt=""/>*/}
                      </span>
                    </div>
                    <div className="col-md-4 ">
                      <div className="line-space" />
                      <div className="line-space" />
                      <p className="text-white">
                        آدرس نمایندگی: مشهد، بلوار وکیل آباد 67، دادگستر 21،
                        پلاک 36
                      </p>
                      <p className="text-white">ﺷﻤﺎره ﺗﻤﺎس: 05135028139</p>
                      <div className="line-space" />
                      <p className="text-white">
                        آدرس دفتر مرکزی: تهران، طرشت، بلوار حمید صالحی، پارک علم
                        و فناوری شریف، پلاک 28، طبقه 6، واحد 601
                      </p>
                      <p className="text-white">ﺷﻤﺎره ﺗﻤﺎس: 02168394222</p>

                      <p className="text-white">
                        آدرس ﮐﺎرﺧﺎﻧﻪ: ﺷﻬﺮک ﺻﻨﻌﺘﯽ ﺷﻤﺲ آﺑﺎد ﺑﻠﻮار ﺑﻬﺎرﺳﺘﺎن خیاﺑﺎن
                        ۱۹ ﯾﺎ بوﻋﻠﯽ ﺳﯿﻨﺎ ﮐﻮﭼﻪ ﻧﺮﮔﺲ ﺳﻮم ﻗﻄﻌﻪ ﺗﯽ اف ﭘﻼک ۱۳۷
                      </p>
                      <p className="text-white">ﺷﻤﺎره ﺗﻤﺎس: ۰۲۱۸۸۳۳۷۵۷۱</p>
                      <p className="text-white">آدرس اﯾﻤﯿﻞ: info@chineh.ir</p>
                    </div>
                    <div className="col-md-4 d-flex flex-column align-items-center ">
                      <div className="line-space" />
                      <div className="line-space" />
                      <Link
                        to={data.startApp ? "/dashboard/product" : "/login"}
                      >
                        <div className="bottom  text-white b-c-c w-50">
                          <span>محصولات</span>
                        </div>
                      </Link>
                      <br />
                      <a href={"tel:02188337571"} className="">
                        <div className="bottom  text-white b-c-c w-50">
                          <span> تماس با ما</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  {/*<hr/>*/}
                </div>
              </footer>
              <div className="line-space" />
              <div className="line-space" />
              <div className="line-space" />
              <div
                className=" d-flex flex-row justify-content-between align-content-center text-white"
                style={{ height: 50 }}
              >
                <div
                  className="col-md-2 border align-self-center"
                  style={{ height: 1 }}
                >
                  {" "}
                </div>
                <div className="col-md-2  d-flex justify-content-center align-items-center">
                  <a
                    href="https://instagram.com/datis_elevator?utm_medium=copy_link"
                    target={"_blank"}
                  >
                    {" "}
                    <img width={30} src={insta} alt="" />
                  </a>
                </div>

                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <a href="https://t.me/datis_elevator" target={"_blank"}>
                    <img width={30} src={telegram} alt="" />
                  </a>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <a href="https://datis-elevator.ir" target={"_blank"}>
                    <img width={30} src={datis} alt="" />
                  </a>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <a
                    href={
                      Platform == "Windows" || Platform == "Mac"
                        ? "https://datis-elevator.ir/Stars"
                        : "https://setaregan.datis-elevator.ir/pwa/"
                    }
                    target={"_blank"}
                  >
                    <img width={30} src={stars} alt="" />
                  </a>
                </div>
                <div
                  className="col-md-2 border align-self-center"
                  style={{ height: 1 }}
                ></div>
              </div>
              <div className="line-space" />
              <div className="line-space" />
              <div className="line-space" />
              <div className="line-space" />
              <div className="line-space" />
            </Route>
          ) : data.login.type === "Representative" ? (
            <Route path="/agent">
              <div className="main">
                <MenuAgent />
                <div className="dark-menu d-n" />
                <div className="left">
                  <button
                    className="menu"
                    id="menu"
                    onClick={() => {
                      document
                        .querySelector(".dark-menu")
                        .classList.toggle("d-n");
                      document
                        .querySelector("#menu")
                        .classList.toggle("opened");
                      document
                        .querySelector("#root")
                        .classList.toggle("no-scroll");
                      document
                        .querySelector(".main .right")
                        .classList.toggle("open");
                      document
                        .querySelector("#menu")
                        .setAttribute("aria-expanded", "true");
                    }}
                    aria-label="Main Menu"
                  >
                    <svg width="50" height="50" viewBox="0 0 100 100">
                      <path
                        className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                      />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path
                        className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                      />
                    </svg>
                  </button>
                  <Route
                    path="/agent/home"
                    component={ProjectRepresentative}
                    exact
                  />
                  <Route
                    path="/agent/history"
                    component={HistoryRepresentative}
                    exact
                  />
                </div>
              </div>
            </Route>
          ) : (
            <Route path="/admin">
              <div className="main">
                <MenuAdmin />
                <div className="dark-menu d-n" />
                <div className="left">
                  <button
                    className="menu"
                    id="menu"
                    onClick={() => {
                      document
                        .querySelector(".dark-menu")
                        .classList.toggle("d-n");
                      document
                        .querySelector("#menu")
                        .classList.toggle("opened");
                      document
                        .querySelector("#root")
                        .classList.toggle("no-scroll");
                      document
                        .querySelector(".main .right")
                        .classList.toggle("open");
                      document
                        .querySelector("#menu")
                        .setAttribute("aria-expanded", "true");
                    }}
                    aria-label="Main Menu"
                  >
                    <svg width="50" height="50" viewBox="0 0 100 100">
                      <path
                        className="line line1"
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                      />
                      <path className="line line2" d="M 20,50 H 80" />
                      <path
                        className="line line3"
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                      />
                    </svg>
                  </button>
                  <Route path="/admin/home" component={Admin} exact />
                  <Route path="/admin/serial" component={AdminSerial} exact />
                  <Route path="/admin/user" component={UserAdmin} exact />
                </div>
              </div>
            </Route>
          )}
        </>
      )}
    </HashRouter>
  );
};

export default Router;
